import { Injectable } from '@angular/core';
import { ApiHttpService, ApiService, ApiSingleResult, ListOptions } from '@capturum/api';
import { responseData } from '@capturum/builders/core';
import {
  CombineLots,
  ConfirmLots,
  DownloadLot,
  FinanceSalesOrderLine,
  FulfillmentLot,
  Lot,
} from '@core/enums/lot.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LotService extends ApiService<Lot> {
  protected endpoint = 'lot';

  constructor(public apiHttp: ApiHttpService) {
    super(apiHttp);
  }

  public editCharacteristic(data: FulfillmentLot, lotId: string, options?: ListOptions): Observable<Lot> {
    let url = `/${this.endpoint}/${lotId}`;

    if (options) {
      url += this.getOptionsQuery(options);
    }

    return this.apiHttp.put(url, data);
  }

  public downloadAuctionLabel(lotId: string, options?: ListOptions): Observable<DownloadLot> {
    let url = `/${this.endpoint}/${lotId}/download-auction-label`;

    if (options) {
      url += this.getOptionsQuery(options);
    }

    return this.apiHttp.get<ApiSingleResult<DownloadLot>>(url).pipe(responseData);
  }

  public combineLots(data: CombineLots, options?: ListOptions): Observable<{ message: string }> {
    let url = `/${this.endpoint}/combine`;

    if (options) {
      url += this.getOptionsQuery(options);
    }

    return this.apiHttp.post(url, data);
  }

  public confirmLots(data: ConfirmLots, options?: ListOptions): Observable<{ message: string }> {
    let url = `/${this.endpoint}/confirm`;

    if (options) {
      url += this.getOptionsQuery(options);
    }

    return this.apiHttp.post(url, data);
  }

  public cancelLot(
    id: string,
    data: { cancel_reason_base_data_value_id: string; _force?: boolean },
    options?: ListOptions,
  ): Observable<void> {
    let url = `/${this.endpoint}/${id}/cancel`;

    if (options) {
      url += this.getOptionsQuery(options);
    }

    return this.apiHttp.patch(url, data);
  }

  public unMatchSales(data: { salesLines: FinanceSalesOrderLine[] }, options?: ListOptions): Observable<Lot> {
    let url = `/${this.endpoint}/unmatch-sales-lines`;

    if (options) {
      url += this.getOptionsQuery(options);
    }

    return this.apiHttp.post(url, data);
  }

  public getLotIdByMask(mask: string): Observable<string> {
    return this.apiHttp.get<ApiSingleResult<string>>(`/${this.endpoint}/find/${mask}`).pipe(
      map((response) => {
        return response?.data;
      }),
    );
  }
}
